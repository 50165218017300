import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/I24NCztSARE">
    <SEO title="Don't Look Away - The Week That Changed the World" />
  </Layout>
)

export default SermonPost
